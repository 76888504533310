// frontend/src/components/NavBar.js

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import { UserContext } from "../context/UserContext";

import logo from "../assets/logo.jpg";
import cartIcon from "../assets/cart-icon.png";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const { user, logout } = useContext(UserContext);

  // Use useCallback to prevent unnecessary re-creation of the function
  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prevState) => !prevState);
  }, []);

  // Close the mobile menu when the location changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <nav className="navbar" role="navigation" aria-label="Main Navigation">
      <div className="logo-container">
        <Link to="/" className="logo-link">
          <img src={logo} alt="ShipGopher Logo" className="logo-img" />
          <span className="logo-text">ShipGopher</span>
        </Link>
      </div>
      <div
        className={`nav-links ${isMobileMenuOpen ? "active" : ""}`}
        tabIndex="-1"
      >
        <Link to="/get-quote" className="nav-link">
          Get a Quote
        </Link>
        <Link to="/track-shipment" className="nav-link">
          Track Shipment
        </Link>
        {user ? (
          <>
            <span className="nav-link welcome-message">
              Welcome, {user.username || user.email}
            </span>
            <Link to="/cart" className="nav-link" aria-label="Cart">
              <img src={cartIcon} alt="Cart" className="cart-icon" />
            </Link>
            <button
              onClick={logout}
              className="nav-link logout-button"
              aria-label="Logout"
            >
              Logout
            </button>
          </>
        ) : (
          <>
            <Link to="/login" className="nav-link">
              Login
            </Link>
            <Link to="/register" className="nav-link highlight">
              Register
            </Link>
          </>
        )}
      </div>
      <button
        className={`mobile-menu-icon ${isMobileMenuOpen ? "active" : ""}`}
        onClick={toggleMobileMenu}
        aria-label="Toggle Navigation Menu"
        aria-expanded={isMobileMenuOpen}
        tabIndex="0"
      >
        <span className="hamburger"></span>
      </button>
    </nav>
  );
};

// Wrap the component with React.memo to prevent unnecessary re-renders
export default React.memo(NavBar);
